// components/Popup.js
import React, { useState } from 'react';
import './styles/Popup.css';

function Popup({ tabs, onClose, onSelect }) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredTabs = tabs.filter(tab =>
    `Summary Conversation ${tab.id}`.toLowerCase().includes(searchTerm)
  );

  return (
    <div className="popup-background">
      <div className="popup">
        <input
          type="text"
          placeholder="Search conversations..."
          onChange={handleSearchChange}
          value={searchTerm}
          className="search-input"
        />
        <ul>
          {filteredTabs.map(tab => (
            <li key={tab.id} className="tab-list-item">
              <button onClick={() => { onSelect(tab.id); onClose(); }} className="tab-item-button">
                Conversation {tab.id}
              </button>
            </li>
          ))}
        </ul>
        <button onClick={onClose} className="close-button">Close</button>
      </div>
    </div>
  );
}

export default Popup;