import React, { useEffect, useState, useRef } from 'react';
import './styles/App.css';
import './styles/Spinner.css';

import { Network } from 'vis-network';
import axios from 'axios';

function GraphNetwork({ nodes, edges, highlightedNodeIds = [1381,1382,1383] }) {
  const [nodePositions, setNodePositions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const networkRef = useRef(null);
  const containerRef = useRef(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${BASE_URL}/get-node-position`)
      .then(response => {
        setNodePositions(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      });
}, []);

useEffect(() => {



  if (Object.keys(nodePositions).length === 0 || isLoading) {
    return;
  }


  setIsLoading(true);
  const container = document.getElementById('network-container');

  const placedNodes = nodes.map(node => {
    // Convert both to strings to ensure consistent comparison
    const stringHighlightedNodeIds = highlightedNodeIds.map(id => String(id));
    

// Then use debugHighlightedNodeIds for comparison instead of the prop
    const isHighlighted = stringHighlightedNodeIds.includes(String(node.id));
  
  
    return {
      ...node,
      x: nodePositions[node.id]?.x || 0,
      y: nodePositions[node.id]?.y || 0,
      color: isHighlighted ? { background: '#A4F76A', border: '#A4F76A' } : node.color || {},
    };
  });
  



    
    const data = {
      nodes: placedNodes,
      edges: edges,
    };

    // Define options for the network
    const options = {
      nodes: {
        shape: 'dot',
        font: {
          size: 14,
          color: '#fff',
        },
        chosen: {
          node: function (values, id, selected, hovering) {
            values.borderWidth = selected || hovering ? 2 : 1;
            values.shadow = selected ? true : false;
          },
        },
      },
      physics: {
        enabled: true,
        stabilization: {
          enabled: true,
          iterations: 100,
          updateInterval: 10,
          onlyDynamicEdges: false,
          fit: true
        },
        solver: 'barnesHut',
        barnesHut: {
          gravitationalConstant: -8000,
          centralGravity: 0.3,
          springLength: 95,
          springConstant: 0.04,
          damping: 0.09,
          avoidOverlap: 0.1
        },
        timestep: 0.5,
        adaptiveTimestep: true
      },
      edges: {
        color: {
          color: '#fff',
          highlight: '#fff',
          hover: '#fff',
        },
        // other edge options...
      },
      // other network options...
    };

    const network = new Network(container, data, options);
    network.on("stabilizationIterationsDone", function () {
      setIsLoading(false); // Disable loading state when graph is stabilized and ready
    });

    return () => {
      network.destroy();
    };
  }, [nodes, edges, nodePositions, highlightedNodeIds]);

  return (
    <div style={{ height: '95%' }}>
      {isLoading && <div className="loading-spinner"></div>}
      <div id="network-container" style={{ height: '100%', visibility: isLoading ? 'hidden' : 'visible' }}>
        {/* The graph will be rendered here */}
      </div>
    </div>
  );
}

export default GraphNetwork;