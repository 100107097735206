import React, { useState, useEffect } from 'react';
import ChatSession from './ChatSession';
import Popup from './Popup';
import './styles/Interface.css';
import { useAuth0 } from '@auth0/auth0-react';

function ChatInterface({ updateHighlightedNodes, onSetSelectedDepth }) {
  const { user: authUser, isAuthenticated } = useAuth0();
  const user = isAuthenticated ? authUser : { given_name: "Guest", sub: "guest_user", family_name: "User",
                                              user: "guest_user", nickname: "guest_user", picture: 'none',
                                              locale: 'en', email: 'none', email_verified: false, sub: 'xxx' };

  const [tabs, setTabs] = useState([{ id: 1 }]);
  const [activeTab, setActiveTab] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [maxVisibleTabs, setMaxVisibleTabs] = useState(4); // Default to 4 tabs for desktop
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  // Listen to window resize to adjust visible tabs
  useEffect(() => {
    const handleResize = () => {
      setMaxVisibleTabs(window.innerWidth < 600 ? 2 : 4); // Set to 2 tabs on screens smaller than 600px
    };

    // Set initial state based on current window size
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const deleteConversation = async (chatId) => {
    try {
      const response = await fetch(`${BASE_URL}/delete-saved-message`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          chat_id: chatId,
          user: user // Ensure you have access to the user object
        }),
      });

      if (response.ok) {
        // Filter out the deleted tab
        const updatedTabs = tabs.filter(tab => tab.id !== chatId);
        setTabs(updatedTabs);

        if (updatedTabs.length > 0) {
          const newActiveTab = chatId === activeTab ? updatedTabs[0].id : activeTab;
          setActiveTab(newActiveTab);
          console.log('New active tab set to:', newActiveTab);
        } else {
          setActiveTab(null);
        }
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  const deleteActiveTab = () => {
    deleteConversation(activeTab);
  };

  const addNewTab = () => {
    const newTabId = Math.max(0, ...tabs.map(t => t.id)) + 1; // Ensure we're always using a new ID
    const newTabs = [...tabs, { id: newTabId }]; // Append the new tab at the end
    setTabs(newTabs);
    setActiveTab(newTabId);
    console.log('New tab added:', newTabId);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    console.log('Active tab set to:', tabId);
  };

  const getDisplayedTabs = () => {
    if (tabs.length > maxVisibleTabs) {
      if (activeTab > 4) {
        return [...tabs.slice(0, 3), tabs.find(tab => tab.id === activeTab)];
      } else {
        return tabs.slice(0, 4);
      }
    }
    return tabs;
  };

  const displayedTabs = getDisplayedTabs();

  return (
    <div className="chat-interface">
      <div className="tabs">
        {displayedTabs.map(tab => (
          <div key={tab.id} className="tab-container">
            <button
              onClick={() => handleTabClick(tab.id)}
              className={tab.id === activeTab ? 'active' : ''}
            >
              Conversation {tab.id}
            </button>
            <small
              className="delete-conversation"
              onClick={(e) => {
                e.stopPropagation();
                handleTabClick(tab.id); // Ensure the tab is active before deleting
                deleteActiveTab();
                console.log('Delete conversation:', tab.id);
              }}
              style={{ cursor: 'pointer' }}
            >
              delete
            </small>
          </div>
        ))}
        <button onClick={addNewTab}>+</button>
        {tabs.length > maxVisibleTabs && (
          <button onClick={() => setShowPopup(true)}>...</button>
        )}
      </div>
      {showPopup && (
        <Popup tabs={tabs} onClose={() => setShowPopup(false)} onSelect={handleTabClick} />
      )}
      <div className="chat-windows">
        {tabs.map(tab => (
          tab.id === activeTab && <ChatSession key={tab.id} id={tab.id}
          updateHighlightedNodes={updateHighlightedNodes}  
          onSetSelectedDepth={onSetSelectedDepth}
          />
        ))}
      </div>
    </div>
  );
}

export default ChatInterface;
